
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Subsribe.css";
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL; // Replace with your API base URL

const SubscribeToUs = () => {
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Show the popup after 4 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSubscribePopup(true);
    }, 4000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    if (!email) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios.post(`https://sheltered-basin-10686-827a5d923252.herokuapp.com/subscribe`, {
        email,
      });
      if (response.status === 200) {
        setSuccessMessage("Thank you for subscribing!");
        setEmail("");
      } else {
        setError("Failed to subscribe. Please try again.");
      }
    } catch (error) {
      console.error("Error subscribing:", error);
      setError("Failed to subscribe. Please try again.");
    }
  };

  const closePopup = () => {
    setShowSubscribePopup(false);
  };

  return (
    <>
      {showSubscribePopup && (
        <div className="subscribe-popup-overlay">
          <div className="subscribe-popup-content">
            <div className="subscribe-popup-image">
              <img
                src="https://t4.ftcdn.net/jpg/04/54/05/57/360_F_454055775_7QBsHbb6Jo4TrGLkFDoMqGwNiF55ydqr.jpg"
                alt="Subscribe Illustration"
                className="subscribe-popup-img"
              />
            </div>
            <div className="subscribe-popup-form">
              <h2 className="subscribe-popup-heading">Subscribe to Us</h2>
              <p className="subscribe-popup-description">
                Stay updated with our latest offers and updates. We value your
                trust and promise not to spam.
              </p>
              <form onSubmit={handleSubscribe} className="subscribe-form">
                <input
                  type="email"
                  placeholder="Your Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="subscribe-email-input"
                  required
                />
                <button type="submit" className="subscribe-submit-button">
                  Subscribe
                </button>
              </form>
              {error && <p className="subscribe-error">{error}</p>}
              {successMessage && <p className="subscribe-success">{successMessage}</p>}
              <button
                type="button"
                onClick={closePopup}
                className="subscribe-close-button"
              >
                &times;
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscribeToUs;
