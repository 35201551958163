import React, { useEffect, useState } from "react";
import axios from "axios";

import Footer from "../component/footers/footer";

const EmailMarketing = () => {
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState("");

  // Fetch email addresses from both APIs on component mount
  useEffect(() => {
    const fetchEmails = async () => {
      try {
        // Fetch emails from the first API
        const response1 = await axios.get("https://sheltered-basin-10686-827a5d923252.herokuapp.com/subscriber");
        const emailList1 = response1.data.map(subscriber => subscriber.email);

        // Fetch emails from the second API
        const response2 = await axios.get("https://backendwoodennepal.nepalmodelsecondaryschool.com/email/emails");
        const emailList2 = response2.data.users.map(user => user.email);

        // Combine the email lists from both APIs
        const combinedEmails = [...emailList1, ...emailList2];
        setEmails(combinedEmails); // Set the combined email list in the state
      } catch (err) {
        setError("Failed to fetch email addresses. Please try again later.");
      }
    };

    fetchEmails();
  }, []);

  // Function to generate and download CSV
  const downloadCSV = () => {
    const csvContent = emails.map(email => `${email}\n`).join('');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'emails.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
        <h1>Email List</h1>
        <h2>Welcome to the email management system!</h2>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {emails.length > 0 ? (
          <>
            <ul>
              {emails.map((email, index) => (
                <li key={index}>{email}</li>
              ))}
            </ul>
            <button onClick={downloadCSV}>Download CSV</button>
          </>
        ) : (
          !error && <p>No emails available to display.</p>
        )}
      </div>
      <Footer />
    </>
  );
};

export default EmailMarketing;
