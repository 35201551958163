import React, { useEffect, useState } from "react";
import { useCart } from "../contextpage/context";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./cartitems.css";
import Footer from "../footers/footer";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function Checkout() {
  const [tokens, setTokens] = useState("");
  const navigate = useNavigate();
  const { cartItems, removeFromCart, updateQuantity } = useCart();

  const calculateTotal = () =>
    cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  const calculateDiscount = () =>
    cartItems.reduce((total, item) => {
      const itemDiscount = (item.oldprice - item.price) * item.quantity;
      return total + (itemDiscount > 0 ? itemDiscount : 0);
    }, 0);

  const handleQuantityChange = (uniqueId, change) => {
    const item = cartItems.find((item) => item.uniqueId === uniqueId);
    if (!item) return;

    const newQuantity = Math.max(1, item.quantity + change);
    updateQuantity(uniqueId, newQuantity);
  };

  useEffect(() => {
    const userTokens = localStorage.getItem("usertoken");
    if (userTokens) {
      setTokens(userTokens);
    }
  }, []);

  const handleBuyNow = () => {
    if (tokens) {
      navigate("/orderconfirmation", {
        state: {
          cartItems,
          total: calculateTotal() - calculateDiscount(),
          discount: calculateDiscount(),
        },
      });
    } else {
      navigate("/login");
    }
  };

  const handleViewProduct = (id) => {
    navigate(`/cart/${id}`);
  };

  return (
    <div className="chk-main-container">
      <div className="chk-container">
        <h4 className="new-p-text">Your cart Items..</h4>
        <form className="chk-cart-form">
          <section className="chk-cart-items">
            <ul className="chk-item-list">
              {cartItems.length === 0 ? (
                <li>No items in cart.</li>
              ) : (
                cartItems.map((item) => {
                  const imagePath = item.image1
                    ? item.image1.startsWith("/")
                      ? item.image1.slice(1)
                      : item.image1
                    : "default-image-path.jpg"; // Placeholder image

                  return (
                    <li key={item.uniqueId} className="chk-cart-item">
                      <div
                        className="chk-item-image"
                        onClick={() => handleViewProduct(item._id)}
                      >
                        <img
                          src={`${apiBaseUrl}/${imagePath}`}
                          alt={item.name || "Product Image"}
                        />
                      </div>
                      <div className="chk-item-details">
                        <h3 className="chk-item-name">{item.name || "Unknown Product"}</h3>
                        <p className="chk-item-color">{item.color || "N/A"}</p>
                        <p className="chk-item-size">{item.size || "N/A"}</p>
                        <div className="chk-item-price">
                          <span className="chk-item-old-price">
                            ₹{item.oldprice || 0}
                          </span>
                          <span className="chk-item-new-price">
                            ₹{(item.price || 0) * (item.quantity || 1)}
                          </span>
                          {item.oldprice && item.price && (
                            <span className="chk-item-discount">
                              You Saved ₹{item.oldprice - item.price} on this
                              order
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="chk-item-quantity">
                        <button
                          type="button"
                          onClick={() =>
                            handleQuantityChange(item.uniqueId, -1)
                          }
                        >
                          -
                        </button>
                        <input
                          type="text"
                          value={item.quantity || 1}
                          readOnly
                        />
                        <button
                          type="button"
                          onClick={() =>
                            handleQuantityChange(item.uniqueId, 1)
                          }
                        >
                          +
                        </button>
                      </div>
                      <div className="chk-btn-remove">
                        <button
                          className="chk-btn-cart"
                          type="button"
                          onClick={() => removeFromCart(item.uniqueId)}
                        >
                          Remove
                        </button>
                      </div>
                    </li>
                  );
                })
              )}
            </ul>
          </section>

          <section className="chk-price-summary">
            <h2 className="chk-summary-heading">Price Details</h2>
            <div className="chk-summary-details">
              <div className="chk-summary-item">
                <span>Price ({cartItems.length} items)</span>
                <span>₹{calculateTotal()}</span>
              </div>
              <div className="chk-summary-item">
                <span>Discount</span>
                <span>₹{calculateDiscount()}</span>
              </div>
              <div className="chk-summary-item">
                <span>Delivery Charges</span>
                <span>Free</span>
              </div>
              <div className="chk-summary-total">
                <span>Total Amount</span>
                <span>₹{calculateTotal() - calculateDiscount()}</span>
              </div>
              <hr />
            </div>
            <div className="chk-savings">
              You will save ₹{calculateDiscount()} on this order
            </div>
            <div className="chk-btn-items">
              <button className="chk-btn-cart" onClick={handleBuyNow}>
                Checkout
              </button>
            </div>
          </section>
        </form>
      </div>
      <Footer />
    </div>
  );
}

export default Checkout;
