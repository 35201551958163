import React, { useEffect, useState } from "react";
import "./mainimage.css";

import Dobleimage from "../../doubleimage/dobleimae";
import Newproducts_page from "../../newproducts/newproducts_page";
import Amazon from "../../amazon_style/amazon";
import Footer from "../../footers/footer";
import Doubleslider from "../../doubleslider/doubeslider";
import PLogin from "../../../poplogin/poplogin";

import BestSeller from "../../../Abestseller/bestseller";
import Twoimagegrid from "../../../Abestseller/twoimagegrid";
import ImageGrid from "../../siderproducts/TopPics";
import Policy from "../../../Apolicy/policy";
import { useCart } from "../../contextpage/context";
import FlashSale from "../../../Templates/FlashSale";
import SubscribeToUs from "../../../Marketting/SubscribeToUs";

function MainImage() {
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  const { googleAuth } = useCart();

  useEffect(() => {
    // Show login popup if user is not authenticated
    const loginTimer = setTimeout(() => {
      const token = localStorage.getItem("usertoken");
      if (!token && !googleAuth) {
        setShowLoginPopup(true);
      }
    }, 8000);

    return () => clearTimeout(loginTimer);
  }, [googleAuth]);

  useEffect(() => {
    // Show subscription popup if the user hasn't subscribed
    const isSubscribed = localStorage.getItem("isSubscribed");
    if (!isSubscribed) {
      const subscribeTimer = setTimeout(() => {
        setShowSubscribePopup(true);
      }, 4000);
      return () => clearTimeout(subscribeTimer);
    }
  }, []);

  const handleSubscriptionClose = () => {
    setShowSubscribePopup(false);
    localStorage.setItem("isSubscribed", "true");
  };

  return (
    <div>
      <div className="heroContainerUnique">
        <div className="imageWrapperUnique">
          <img
            src="https://static.vecteezy.com/system/resources/thumbnails/015/939/382/small_2x/banner-with-modern-furniture-and-copy-space-for-your-advertisement-text-or-logo-furniture-store-interior-details-furnishings-sale-or-interior-project-concept-template-with-free-space-3d-rendering-photo.jpg"
            alt="Hero Background"
            className="heroImageUnique"
          />
          <div className="overlayUnique"></div>
          <div className="contentUnique">
            {/* <h1 className="titleUnique">Welcome to My Personal Website</h1> */}
            <p className="subtitleUnique">
              Discover amazing experiences and unforgettable moments
            </p>
            <button className="ctaUnique">Get Started</button>
          </div>
        </div>
      </div>

      {/* Other components */}
      <Dobleimage />
      <ImageGrid />
      <FlashSale />
      <Newproducts_page />
      <Twoimagegrid />
      <Doubleslider />
      <BestSeller />
      <Amazon />
      <Policy />
      <Footer />

      {/* Conditional Login Popup */}
      {showLoginPopup && <PLogin setShowLoginPopup={setShowLoginPopup} />}

      {/* Conditional Subscribe Popup */}
      {showSubscribePopup && (
        <SubscribeToUs setShowSubscribePopup={handleSubscriptionClose} />
      )}
    </div>
  );
}

export default MainImage;
